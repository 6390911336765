<template>  
  <b-card v-if="isloading">
    <center><b-spinner></b-spinner></center>
  </b-card>
  <b-card v-else>
    <b-card-title>
      <div class="d-flex w-100">
          <b-col sm="12" class="d-flex">
            <div class="w-100">
              <b-row class="ml-1">
                <b-col><h3 >{{cow.tiername}}</h3></b-col>
                <b-col class="ml-4">
                  <b-row>{{cow.AGD}}</b-row>
                  <b-row>{{cow.ZWLabelMT}}</b-row>
                </b-col>
              </b-row>
              <b-row class="ml-1">
                <b-col><h6>{{cow.tvdnr}}</h6></b-col>
                <b-col><h6>{{cow.GenMarkerHorn}}</h6></b-col>
                <b-col><h6>né.: {{cow.birthdate}}</h6></b-col>
                <b-col><h6>{{cow.GenMarkerDoppellender}}</h6></b-col>
              </b-row>
            </div>
            <div class="mr-0">
              <h3>{{cow.katalognr}}</h3>
              <h5>{{cow.Rasse}}</h5>
            </div>
          </b-col>
      </div>
    </b-card-title>
      <b-card-text class="mt-2 pt-2 w-100 border-top">
        <b-row class="w-100 m-0">
          <b-col class="pl-0 py-1">V/P: {{cow.V_Langname}}</b-col>
          <b-col class="py-1">{{cow.MV_Ohrmarkennummer}}</b-col>
          <b-col class="py-1">{{cow.MV_Langname}}</b-col>
        </b-row>
        <b-row class="w-100 m-0">
          <b-col class="pl-0 py-1">M/M: {{cow.M_Langname}}</b-col>
          <b-col class="py-1">{{cow.M_LB}}</b-col>
          <b-col class="py-1">{{cow.MM_Langname}}</b-col>
        </b-row>
        <b-row class="w-100 m-0">
          <b-col class="pl-0 py-1">Kalb/ Veau:</b-col>
          <b-col class="pl-0 py-1">{{cow.GJ205}}</b-col>
        </b-row>
        <b-row class="w-100 m-0">
          <b-col class="pl-0 py-1"></b-col>
          <b-col class="pl-0 py-1">{{cow.FettMT}}</b-col>
        </b-row>
        <b-row class="w-100 m-0">
          <b-col class="pl-0 py-1">{{cow.SGMT}}</b-col>
          <b-col class="pl-0 py-1">{{cow.Datum_letzte_LB}}</b-col>
        </b-row>
        <b-row class="w-100 m-0 mt-3">
          <b-col sm class="p-1">Züchter<br>Eleveur</b-col>
          <b-col sm class="p-1 font-weight-bold"><h4>{{cow.Zuechter}}, {{cow.ZuechterOrt}}</h4></b-col>
        </b-row>
        <b-row class="w-100 m-0">
          <b-col sm class="p-1">Besitzer<br>Propriétaire</b-col>
          <b-col sm class="p-1 font-weight-bold"><h4>{{cow.Besitzer}}, {{cow.BesitzerOrt}}</h4></b-col>
        </b-row>
      </b-card-text>
  </b-card>
</template>

<script>

import Auction from '@/states/auction.js';
export default {
  name: 'Cow-Card',
  data(){
    return {
      cow: {
      },
      isloading: true,
    }
  },
  props: {
    id:{
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async Cow(id){
      try {
        const response = await Auction.cow(id);
        this.cow = response
        this.isloading = false
      } catch(error){
        this.msg = error
        // console.log(this.msg)
      }
    }
  },
  components: {},
  mounted(){
    if (this.isActive){
      this.Cow(this.id)
    }
    window.scrollTo(0, 0)
  },
  computed:{
    isonline(){
      if (!this.online){
        return 'isoffline'
      }
      return ''
    }
  },
  watch: {
    isActive: function (val) {
      if (val){

        // this.$socket.emit('showdata',this.id)
        // this.sockets.subscribe('actualvorgebot', (data) => {
        //   if (data[0]==this.id){
        //     var jsondata = JSON.parse(data[1])
        //     if (jsondata.auktionvalue == 0) {
        //       this.newprice = jsondata.new
        //       this.actprice = jsondata.act
        //       this.saleuser = jsondata.saleuser
        //       this.isOpen = true
        //     } else {
        //       this.isOpen = false
        //     }
        //   }
        // });
        // this.sockets.subscribe('actualprice', (data) => {
        //   var jsondata = JSON.parse(data)
        //   if (jsondata.cow==this.id){
        //     if (jsondata.auktionvalue == 0) {
        //       this.newprice = jsondata.new
        //       this.actprice = jsondata.act
        //       this.saleuser = jsondata.saleuser
        //       this.isOpen = true
        //     } else {
        //       this.isOpen = false
        //     }
        //   }
        // });
        this.Cow(this.id)
      } else {
        // this.sockets.unsubscribe('actualvorgebot')
          this.newprice = 0
          this.actprice = 0
          this.isOpen = false
      }
    }
  }
  
}
</script>

<style style="scss" scoped>
.cardsize  {
  height: 150px;
}
.right-0{
  right: 0%;
}
.bottom-0{
  bottom: 0%;
}
.live {
  color: #2020ff;
}
.isoffline {
  background-color: #e0e0e0;
}
</style>
