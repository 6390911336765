import Vue from 'vue'
import App from './App.vue'

import i18n from './lang/i18n.js';
import store from './states/store.js';
import {router} from '@/router/routes';
import VueHtmlToPaper from 'vue-html-to-paper';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VueNativeSock from 'vue-native-websocket'
Vue.use(VueNativeSock, 'wss://viancobackend.swissliveauction.ch/ws', {
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 2000, // (Number) how long to initially wait before attempting a new (1000)
  store: store, format: 'json'
})

import './scss/custom.scss'
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueHtmlToPaper);
Vue.use(Vuetify)

const opts = {}

export default new Vuetify(opts)



Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app')
